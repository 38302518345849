import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetAvailablePlans, SetSelectedPlan, SetSelectedPlanInfo, SetSelectedSlot } from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './Congrats.module.scss'

enum Device_Type {
  ios = 'IOS',
  android = 'Android',
  desktop = 'Desktop',
}

const Congrats = () => {
  const navigate = useNavigate()
  const appointmentTime = useAppSelector((state) => state.selectedSlot.selectedSlot)
  const dispatch = useAppDispatch()
  const [device, setDevice] = useState('')
  const studios = useAppSelector((state) => state.studios.studios)
  const selectedStudio = useAppSelector((state) => state.selectedStudio.studio)
  const isMoreThanOneStudio = useAppSelector((state) => state.isMoreThanOneStudio.isMoreThanOneStudio)

  useEffect(() => {
    const userAgent = navigator.userAgent

    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)

    if (isMobile) {
      if (/iPad/i.test(userAgent)) {
        setDevice(Device_Type.ios)
      } else if (/iPhone/i.test(userAgent)) {
        setDevice(Device_Type.ios)
      } else if (/Android/i.test(userAgent)) {
        setDevice(Device_Type.android)
      }
    } else {
      setDevice(Device_Type.desktop)
    }
  }, [])

  const toAppStore = () => {
    window.location.href = `https://apps.apple.com/us/app/kiro-chiropractic-adjustments/id6475482372`
  }

  const toPlayStore = () => {
    window.location.href = `https://play.google.com/store/apps/details?id=com.getkiro.kiro`
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Welcome',
      page_location: '/welcome',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', left: '18px', top: '13px' }}>
            <span>{'Congrats!'}</span>
            <span>{`You're booked!`}</span>
          </div>
          <img src={'/logo2.png'} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.locationContainer}>
          {studios && (
            <div className={styles.locationDetails}>
              <div className={styles.locationTextContainer}>
                <div className={styles.locationWrapper}>
                  <div className={styles.appointmentTime}>
                    {appointmentTime
                      ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), 'EEEE, MMM dd')
                      : ''}
                    <div>
                      {appointmentTime
                        ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), 'h:mm a')
                        : ''}
                    </div>
                  </div>
                  <div>
                    <div className={styles.locationText} style={{ fontWeight: 700 }}>
                      {isMoreThanOneStudio ? selectedStudio?.name : studios[0]?.name}
                    </div>
                    <div className={styles.locationText}>
                      {isMoreThanOneStudio ? selectedStudio?.address : studios[0]?.address}
                    </div>
                    {isMoreThanOneStudio ? (
                      <div className={styles.cityAndState}>
                        <div style={{ marginRight: '4px' }}>{selectedStudio?.city.name + ','}</div>
                        <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{selectedStudio?.state}</div>
                        <div>{selectedStudio?.zipCode}</div>
                      </div>
                    ) : (
                      <div className={styles.cityAndState}>
                        <div style={{ marginRight: '4px' }}>{studios[0]?.city.name + ','}</div>
                        <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{studios[0]?.state}</div>
                        <div>{studios[0]?.zipCode}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <img
                style={{ width: '145px', height: '150px', cursor: 'pointer', objectFit: 'cover' }}
                className={styles.mapImageItem}
                src={isMoreThanOneStudio ? selectedStudio?.mapImage || '' : studios[0]?.mapImage || ''}
                alt="map"
                onClick={() => {
                  if (studios && selectedStudio && selectedStudio?.mapLocation && isMoreThanOneStudio) {
                    window.open(selectedStudio.mapLocation, '_blank')
                  } else if (studios && studios.length === 1 && studios[0]?.mapLocation) {
                    window.open(studios[0].mapLocation, '_blank')
                  }
                  //   window.location.href =
                  //     'https://www.google.com/maps/place/302+Mott+St,+New+York,+NY+10012,+USA/@40.7248592,-73.9940347'
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.description}>
          Please download the KIRO app below. You will need it to check in to your appointment.
        </div>
        <Footer
          buttonText={'Download KIRO'}
          isLoading={false}
          trackerIndex={isMoreThanOneStudio ? 5 : 4}
          shouldShowFour={true}
          shouldShowFive={isMoreThanOneStudio}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            if (device === Device_Type.android) {
              toPlayStore()
            } else if (device === Device_Type.ios) {
              toAppStore()
            } else {
              navigate('/download')
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default Congrats
