import { Referral_Flow_Action_Type } from 'AppConstants/AppConstants'
import { studio } from 'redux/persist'

export interface ITime {
  index: number
  value: string
}

//Selected Slot Persist
interface IScheduleInitialValues {
  scheduleObject: {
    appointmentTime: string
    timezone: string
    clinicId: string | undefined | null
  }
  index?: number | null
  value?: ITime | null
  date?: any
}
interface SelectedSlotReferralFlowState {
  selectedSlotReferralFlow: IScheduleInitialValues | null
}
const selectedSlotReferralFlowState: SelectedSlotReferralFlowState = {
  selectedSlotReferralFlow: null,
}

export const SetSelectedSlotReferralFlow = (selectedSlotReferralFlow: IScheduleInitialValues | null) => ({
  type: Referral_Flow_Action_Type.SELECTED_SLOT_REFERRAL_FLOW,
  payload: selectedSlotReferralFlow,
})

export const selectedSlotReferralFlowPersist = (
  state = selectedSlotReferralFlowState,
  action: { type: Referral_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Referral_Flow_Action_Type.SELECTED_SLOT_REFERRAL_FLOW:
      return {
        ...state,
        selectedSlotReferralFlow: action.payload as IScheduleInitialValues,
      }
    default:
      return state
  }
}

// Studios

interface studiosReferral {
  studiosReferral: Array<studio>
}
const studiosReferralState: studiosReferral = {
  studiosReferral: [],
}

export const SetStudiosReferral = (studiosReferral: Array<studio>) => ({
  type: Referral_Flow_Action_Type.STUDIOS_REFERRAL_FLOW,
  payload: studiosReferral,
})

export const studiosReferralPersist = (
  state = studiosReferralState,
  action: { type: Referral_Flow_Action_Type; payload: Array<studio> },
) => {
  switch (action.type) {
    case Referral_Flow_Action_Type.STUDIOS_REFERRAL_FLOW:
      return {
        ...state,
        studiosReferral: action.payload,
      }
    default:
      return state
  }
}

// Selected Studio

interface SelectedStudioReferral {
  studioReferral: studio | null
}
const selectedStudioReferralState: SelectedStudioReferral = {
  studioReferral: null,
}

export const SetSelectedStudioReferral = (selectedStudioReferral: studio | null) => ({
  type: Referral_Flow_Action_Type.STUDIO_REFERRAL_FLOW,
  payload: selectedStudioReferral,
})

export const selectedStudioReferralPersist = (
  state = selectedStudioReferralState,
  action: { type: Referral_Flow_Action_Type; payload: studio },
) => {
  switch (action.type) {
    case Referral_Flow_Action_Type.STUDIO_REFERRAL_FLOW:
      return {
        ...state,
        studioReferral: action.payload,
      }
    default:
      return state
  }
}

// isMoreThanOneStudio

interface isMoreThanOneStudioReferral {
  isMoreThanOneStudioReferral: boolean
}
const isMoreThanOneStudioReferral: isMoreThanOneStudioReferral = {
  isMoreThanOneStudioReferral: false,
}

export const SetIsMoreThanOneStudioReferral = (isMoreThanOneStudioReferral: boolean) => ({
  type: Referral_Flow_Action_Type.IS_MORE_THAN_ONE_STUDIO_REFERRAL,
  payload: isMoreThanOneStudioReferral,
})

export const isMoreThanOneStudioReferralPersist = (
  state = isMoreThanOneStudioReferral,
  action: { type: Referral_Flow_Action_Type; payload: boolean },
) => {
  switch (action.type) {
    case Referral_Flow_Action_Type.IS_MORE_THAN_ONE_STUDIO_REFERRAL:
      return {
        ...state,
        isMoreThanOneStudioReferral: action.payload,
      }
    default:
      return state
  }
}

// // Slot booked already Persist

// interface SlotBookedError {
//   slotBookedAlreadyReferralFlow: boolean
// }
// const slotBookedAlreadyReferralFlowState: SlotBookedError = {
//   slotBookedAlreadyReferralFlow: false,
// }

// export const SetSlotBookedAlreadyReferralFlow = (slotBookedAlreadyReferralFlow: boolean) => ({
//   type: Referral_Flow_Action_Type.SLOT_BOOKED_ALREADY_REFERRAL_FLOW,
//   payload: slotBookedAlreadyReferralFlow,
// })

// export const slotBookedReferralFlowPersist = (
//   state = slotBookedAlreadyReferralFlowState,
//   action: { type: Referral_Flow_Action_Type; payload: any },
// ) => {
//   switch (action.type) {
//     case Referral_Flow_Action_Type.SLOT_BOOKED_ALREADY_REFERRAL_FLOW:
//       return {
//         ...state,
//         slotBookedAlreadyReferralFlow: action.payload,
//       }
//     default:
//       return state
//   }
// }
