import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, CircularProgress, DialogTitle, Grid, Modal, Typography, useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import format from 'date-fns/format'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import HorizontalCalendar from 'components/shared/HorizontalCalendar'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { CheckoutForm } from './CheckoutForm'
import styles from './Payment.module.scss'

const PaymentOffers = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [disableBackButton, setDisableBackButton] = useState(false)

  const plans = useAppSelector((state) => state.paymentPlansOffer.plans)
  const purchasedPlan = useAppSelector((state) => state.purchasedPlanOffer.purchasedPlan)

  //cardPayment
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY || ''
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

  const options: StripeElementsOptions = {
    clientSecret: purchasedPlan?.clientSecret,
    appearance: {
      theme: 'night',
      variables: {
        colorBackground: '#050624',
      },
    },
  }

  const plan = useMemo(() => {
    if (plans) {
      const filterOnDemand = plans?.filter((p) => p.type === 'on-demand')
      return filterOnDemand
    } else {
      return null
    }
  }, [plans])

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      // if (response?.data?.data?.screeningStatus === '') {
      //   navigate('/welcome')
      // }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (id === 'facebook') {
      //@ts-ignore
      fbq('track', 'InitiateCheckout')
    }
  }, [])

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <div>
      <div className={styles.paymentContainer}>
        <Header
          headerText="Add Payment Details"
          showBackButton={true}
          onBack={() => {
            disableBackButton ? undefined : navigate(`/offers/${id}/confirm-visit`)
          }}
        ></Header>
        <Elements stripe={stripePromise} options={options}>
          <div className={styles.paymentContainerDetails}>
            <CheckoutForm
              clientSecret={purchasedPlan?.clientSecret}
              planId={purchasedPlan?.paymentId}
              purchaseAmount={plan ? plan[0]?.amount / 100 : 60}
              disableBackButton={() => {
                setDisableBackButton(true)
              }}
              enableBackButton={() => {
                setDisableBackButton(false)
              }}
            ></CheckoutForm>
          </div>
        </Elements>
      </div>
    </div>
  )
}

export default PaymentOffers
