import { useEffect, useMemo, useState } from 'react'
import { Button, Dialog, DialogTitle } from '@mui/material'
import axios from 'axios'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetPurchasedPlanBooking, SetTimeLeftToExpireBooking } from 'redux/booking'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './ConfirmAppointment.module.scss'

const ConfirmAppointmentBooking = () => {
  const styless: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //   position: 'absolute' as 'absolute',
    //   top: '44%',
    //   left: '47.5%',
    //   transform: 'translate(-50%, -50%)',
    width: 300,
    height: 170,
    overflow: 'hidden',
    backgroundColor: '#050624',
    color: 'white',
    borderRadius: '8px',
    p: 4,
  }
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const appointmentTime = useAppSelector((state) => state.booking.selectedSlotBooking)
  const paymentDone = useAppSelector((state) => state.booking.paymentDoneBooking)
  const plans = useAppSelector((state) => state.booking.availablePlansBooking)
  const visitCount = useAppSelector((state) => state.booking.visitCountBooking)
  const purchasedPlan = useAppSelector((state) => state.booking.purchasedPlanBooking)
  const selectedPlan = useAppSelector((state) => state.booking.selectedPlanBooking)
  const studios = useAppSelector((state) => state.booking.studiosBooking)
  const selectedStudio = useAppSelector((state) => state.booking.selectedStudioBooking)
  const isMoreThanOneStudio = useAppSelector((state) => state.booking.isMoreThanOneStudioBooking)

  const referralCode = useAppSelector((state) => state.booking.referralCodeBooking)
  const timeLeft: any = useAppSelector((state) => state.booking.timeLeftToExpireBooking)
  const [error, setError] = useState(false)
  const [slotNotFound, setSlotNotFound] = useState(false)

  const plan = useMemo(() => {
    if (plans) {
      const filterOnDemand = plans?.filter((p) => p.type === 'on-demand')
      return filterOnDemand
    } else {
      return null
    }
  }, [plans])

  console.log(plan, 'plan list')

  const schedulePhysicalVisit = async () => {
    if (appointmentTime?.scheduleObject) {
      const response: any = await axios
        .post(AppConstants.API_URL + '/visit/chiropractor/appointment', appointmentTime.scheduleObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })

      if (response?.data?.code === 'success') {
        // ReactGA.event('conversion', {
        //   send_to: process.env.REACT_APP_GA_CONVERSION_ID || 'AW-11337579902/40e2CN_xnqkZEP76lp4q',
        //   value: plan && plan[0] && plan[0]?.amount > 0 ? plan[0].amount / 100 : 0,
        //   currency: 'USD',
        // })
        navigate(`/booking/${id}/welcome`)
      } else {
        if (response?.data?.code === 'slot_booked_already') {
          setError(true)
        } else if (response?.data?.code === 'slot_not_found') {
          setSlotNotFound(true)
        }
        return false
      }
    } else {
      return false
    }
  }

  async function purchasePlan(selectedPlanId: string | undefined) {
    try {
      const response: any = await axios
        .post(
          `${AppConstants.API_URL}/payment-plan/purchase`,
          { planId: selectedPlanId, quantity: 1 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => {
          if (res && res?.data && res?.data?.data) {
            dispatch(SetPurchasedPlanBooking(res?.data?.data))
            navigate(`/booking/${id}/payment`)
          }
        })
        .catch((err) => {
          if (err) {
            alert('Something went wrong. Please try again.')
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'booking|Payment',
      page_location: `/booking/${id}/payment`,
    })
  }, [])

  useEffect(() => {
    dispatch(SetTimeLeftToExpireBooking(timeLeft ?? 299))
  }, [])

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        dispatch(SetTimeLeftToExpireBooking(timeLeft && timeLeft - 1))
      }, 1000)

      return () => clearInterval(timerId)
    }
  }, [timeLeft])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText="Confirm Visit"
          showBackButton={true}
          onBack={() => {
            navigate(`/booking/${id}/physical-visit`)
          }}
        ></Header>
        <div className={styles.locationContainer}>
          {studios && (
            <div className={styles.locationDetails}>
              <div className={styles.locationTextContainer}>
                <div className={styles.locationWrapper}>
                  <div className={styles.appointmentTime}>
                    {appointmentTime
                      ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), 'EEEE, MMM dd')
                      : ''}
                    <div>
                      {appointmentTime
                        ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), 'h:mm a')
                        : ''}
                    </div>
                  </div>
                  <div>
                    <div className={styles.locationText} style={{ fontWeight: 700 }}>
                      {isMoreThanOneStudio ? selectedStudio?.name : studios[0]?.name}
                    </div>
                    <div className={styles.locationText}>
                      {isMoreThanOneStudio ? selectedStudio?.address : studios[0]?.address}
                    </div>
                    {isMoreThanOneStudio ? (
                      <div className={styles.cityAndState}>
                        <div style={{ marginRight: '4px' }}>{selectedStudio?.city.name + ','}</div>
                        <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{selectedStudio?.state}</div>
                        <div>{selectedStudio?.zipCode}</div>
                      </div>
                    ) : (
                      <div className={styles.cityAndState}>
                        <div style={{ marginRight: '4px' }}>{studios[0]?.city.name + ','}</div>
                        <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{studios[0]?.state}</div>
                        <div>{studios[0]?.zipCode}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <img
                style={{ width: '145px', height: '150px', cursor: 'pointer', objectFit: 'cover' }}
                className={styles.mapImageItem}
                src={isMoreThanOneStudio ? selectedStudio?.mapImage || '' : studios[0]?.mapImage || ''}
                alt="map"
                onClick={() => {
                  if (studios && selectedStudio && selectedStudio?.mapLocation && isMoreThanOneStudio) {
                    window.open(selectedStudio.mapLocation, '_blank')
                  } else if (studios && studios.length === 1 && studios[0]?.mapLocation) {
                    window.open(studios[0].mapLocation, '_blank')
                  }
                  //   window.location.href =
                  //     'https://www.google.com/maps/place/302+Mott+St,+New+York,+NY+10012,+USA/@40.7248592,-73.9940347'
                }}
              />
            </div>
          )}

          {error && (
            <Dialog
              onClose={() => {
                // setError(false)
              }}
              open={error}
              PaperProps={{ style: styless }}
              className={styles.modalPopUp}
            >
              <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                We’re sorry, the slot you picked has been booked already. Please select another slot.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setError(false)
                  navigate(`/booking/${id}/physical-visit`)
                }}
              >
                Reschedule
              </Button>
            </Dialog>
          )}
          {slotNotFound && (
            <Dialog
              onClose={() => {
                // setError(false)
              }}
              open={slotNotFound}
              PaperProps={{ style: styless }}
              className={styles.modalPopUp}
            >
              <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                We’re sorry, the slot you picked is no longer available. Please select another slot.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setSlotNotFound(false)
                  navigate(`/booking/${id}/physical-visit`)
                }}
              >
                Reschedule
              </Button>
            </Dialog>
          )}
        </div>
        {plan &&
        plan[0] &&
        plan[0]?.amount &&
        visitCount &&
        visitCount?.onDemandCount === 0 &&
        visitCount?.subscriptionCount === 0 &&
        referralCode ? (
          <div className={styles.offerDetails}>
            <span className={styles.offerDetailsHeader}>Offer Details</span>
            <div className={styles.offerText}>
              Due to limited availability for this promotion, we require a non-refundable payment of
              <b> ${plan[0]?.amount / 100}</b> to secure your spot. Please complete your payment below. We're looking
              forward to meeting you in our studio!
            </div>
          </div>
        ) : null}
        {visitCount && visitCount?.onDemandCount === 0 && visitCount?.subscriptionCount === 0 && referralCode ? (
          <div className={styles.offerExpires}>
            Offer Expires In: <span className={styles.timer}>{formatTime(timeLeft)}</span>
          </div>
        ) : null}
        {!(visitCount?.onDemandCount === 0 && referralCode) ? (
          <div className={styles.cancellationPolicy}>
            <div className={styles.policyHeader}>Cancellation Policy</div>
            <div className={styles.cancellation}>
              Appointments canceled or rescheduled at least 12 hours in advance of the appointment time will not incur a
              penalty. Cancellations made between 12 hours and 1 hour in advance of the appointment time will result in
              a $10 penalty. If canceled less than 1 hour in advance of the appointment time or in the case of a
              no-show, a $20 penalty will apply. This policy ensures that our doctors can offer the slot to other
              patients in need of care.
            </div>
          </div>
        ) : null}
        <Footer
          buttonText={
            visitCount && (visitCount?.onDemandCount !== 0 || visitCount?.subscriptionCount !== 0)
              ? 'Confirm'
              : !paymentDone && visitCount?.onDemandCount === 0 && visitCount?.subscriptionCount === 0
              ? 'Confirm and Pay'
              : 'Confirm'
          }
          isLoading={false}
          trackerIndex={referralCode === null ? (isMoreThanOneStudio ? 4 : 3) : isMoreThanOneStudio ? 3 : 2}
          shouldShowFour={isMoreThanOneStudio}
          shouldShowFive={referralCode === null && isMoreThanOneStudio ? true : false}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            if (
              visitCount &&
              (visitCount?.onDemandCount !== 0 || visitCount?.subscriptionCount !== 0) &&
              appointmentTime?.scheduleObject
            ) {
              schedulePhysicalVisit()
            } else if (
              plan &&
              plan[0]?.id &&
              visitCount &&
              visitCount?.onDemandCount === 0 &&
              visitCount?.subscriptionCount === 0 &&
              appointmentTime?.scheduleObject
            ) {
              if (!paymentDone) {
                if (!referralCode) {
                  purchasePlan(selectedPlan?.id)
                } else {
                  purchasePlan(plan[0]?.id)
                }
              }
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default ConfirmAppointmentBooking
