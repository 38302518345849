import { Action_Type } from 'AppConstants/AppConstants'
import { ITime } from 'pages/V1/PhysicalVisit'

//Redux Persilst

// Available Plan Persist

export interface PlanDetails {
  amount: number
  count: number
  frequency: null
  id: string
  isActive: boolean
  description: string
  maxCount: number
  name: string
  stripeProductId: string
  type: string
}

interface AvailablePlanPersistState {
  availablePlans: Array<PlanDetails>
}
const availablePlanPersistState: AvailablePlanPersistState = {
  availablePlans: [],
}

export const SetAvailablePlans = (availablePlans: any) => ({
  type: Action_Type.AVAILABLE_PLAN,
  payload: availablePlans,
})

export const availablePlanPersist = (
  state = availablePlanPersistState,
  action: { type: Action_Type; payload: any },
) => {
  switch (action.type) {
    case Action_Type.AVAILABLE_PLAN:
      return {
        ...state,
        availablePlans: action.payload as Array<PlanDetails>,
      }
    default:
      return state
  }
}

//Selected Plan Persist
interface SelectedPlan {
  clientSecret: string
  paymentId: string
  paymentIntentId: string
}

interface SelectedPlanPersistState {
  selectedPlan: SelectedPlan | null
}
const selectedPlanPersistState: SelectedPlanPersistState = {
  selectedPlan: null,
}

export const SetSelectedPlan = (selectedPlan: SelectedPlan | null) => ({
  type: Action_Type.SELECTED_PLAN,
  payload: selectedPlan,
})

export const selectedPlanPersist = (state = selectedPlanPersistState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload as SelectedPlan,
      }
    default:
      return state
  }
}

//Selected Slot Persist
interface IScheduleInitialValues {
  scheduleObject: {
    appointmentTime: string
    timezone: string
    clinicId: string | undefined | null
  }
  index?: number | null
  value?: ITime | null
  date?: any
}
interface SelectedSlotPersistState {
  selectedSlot: IScheduleInitialValues | null
}
const selectedSlotPersistState: SelectedSlotPersistState = {
  selectedSlot: null,
}

export const SetSelectedSlot = (selectedSlot: IScheduleInitialValues | null) => ({
  type: Action_Type.SELECTED_SLOT,
  payload: selectedSlot,
})

export const selectedSlotPersist = (state = selectedSlotPersistState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.SELECTED_SLOT:
      return {
        ...state,
        selectedSlot: action.payload as IScheduleInitialValues,
      }
    default:
      return state
  }
}

//Selected Plan Info Persist

interface SelectedPlanInfoPersistState {
  selectedPlanInfo: PlanDetails | null
}
const selectedPlanInfoPersistState: SelectedPlanInfoPersistState = {
  selectedPlanInfo: null,
}

export const SetSelectedPlanInfo = (selectedPlanInfo: PlanDetails | null) => ({
  type: Action_Type.SELECTED_PLAN_INFO,
  payload: selectedPlanInfo,
})

export const selectedPlanInfoPersist = (
  state = selectedPlanInfoPersistState,
  action: { type: Action_Type; payload: any },
) => {
  switch (action.type) {
    case Action_Type.SELECTED_PLAN_INFO:
      return {
        ...state,
        selectedPlanInfo: action.payload as PlanDetails,
      }
    default:
      return state
  }
}

// Slot booked already Persist

interface SlotBookedErrorType {
  slotBookedAlready: boolean
}
const slotBookedAlreadyState: SlotBookedErrorType = {
  slotBookedAlready: false,
}

export const SetSlotBookedAlready = (slotBooked: boolean) => ({
  type: Action_Type.SLOT_BOOKED_ALREADY,
  payload: slotBooked,
})

export const slotBookedPersist = (state = slotBookedAlreadyState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.SLOT_BOOKED_ALREADY:
      return {
        ...state,
        slotBookedAlready: action.payload,
      }
    default:
      return state
  }
}

// Slot not found Persist

interface SlotNotFoundErrType {
  slotNotFound: boolean
}
const slotNotFoundState: SlotNotFoundErrType = {
  slotNotFound: false,
}

export const SetSlotNotFound = (slotNotFound: boolean) => ({
  type: Action_Type.SLOT_NOT_FOUND,
  payload: slotNotFound,
})

export const slotNotFoundPersist = (state = slotNotFoundState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.SLOT_NOT_FOUND:
      return {
        ...state,
        slotNotFound: action.payload,
      }
    default:
      return state
  }
}

// Payment Done Persist

interface PaymentDone {
  paymentDone: boolean
}
const paymentDoneState: PaymentDone = {
  paymentDone: false,
}

export const SetPaymentDone = (paymentDone: boolean) => ({
  type: Action_Type.PAYMENT_DONE,
  payload: paymentDone,
})

export const paymentDonePersist = (state = paymentDoneState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.PAYMENT_DONE:
      return {
        ...state,
        paymentDone: action.payload,
      }
    default:
      return state
  }
}

// studiosInfo

export interface studio {
  id: string
  name: string
  coordinates: string | null
  address: string
  country: string | null
  state: string
  studioEmail: string
  zipCode: string
  reviewLink: string | null
  mapLocation: string | null
  mapImage: string | null
  createdAt: string
  updatedAt: string
  city: {
    name: string
    isActive: boolean
  }
}

interface studios {
  studios: Array<studio>
}
const studiosState: studios = {
  studios: [],
}

export const SetStudios = (studios: Array<studio>) => ({
  type: Action_Type.STUDIOS,
  payload: studios,
})

export const studiosPersist = (state = studiosState, action: { type: Action_Type; payload: Array<studio> }) => {
  switch (action.type) {
    case Action_Type.STUDIOS:
      return {
        ...state,
        studios: action.payload,
      }
    default:
      return state
  }
}

// Selected Studio

interface SelectedStudio {
  studio: studio | null
}
const selectedStudioState: SelectedStudio = {
  studio: null,
}

export const SetSelectedStudio = (selectedStudio: studio | null) => ({
  type: Action_Type.STUDIO,
  payload: selectedStudio,
})

export const selectedStudioPersist = (state = selectedStudioState, action: { type: Action_Type; payload: studio }) => {
  switch (action.type) {
    case Action_Type.STUDIO:
      return {
        ...state,
        studio: action.payload,
      }
    default:
      return state
  }
}

// isMoreThanOneStudio

interface isMoreThanOneStudio {
  isMoreThanOneStudio: boolean
}
const isMoreThanOneStudio: isMoreThanOneStudio = {
  isMoreThanOneStudio: false,
}

export const SetIsMoreThanOneStudio = (isMoreThanOneStudio: boolean) => ({
  type: Action_Type.IS_MORE_THAN_ONE_STUDIO,
  payload: isMoreThanOneStudio,
})

export const isMoreThanOneStudioPersist = (
  state = isMoreThanOneStudio,
  action: { type: Action_Type; payload: boolean },
) => {
  switch (action.type) {
    case Action_Type.IS_MORE_THAN_ONE_STUDIO:
      return {
        ...state,
        isMoreThanOneStudio: action.payload,
      }
    default:
      return state
  }
}
