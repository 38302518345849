import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, TextField, Dialog, DialogTitle } from '@mui/material'
import axios from 'axios'
import ReactGA from 'react-ga4'
import PhoneInput from 'react-phone-input-2'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetSkipCongratsReferral } from 'redux/Referral/nonPersist'
import {
  SetIsMoreThanOneStudioReferral,
  SetSelectedSlotReferralFlow,
  SetSelectedStudioReferral,
  SetStudiosReferral,
} from 'redux/Referral/persist'
import { useAppDispatch } from 'redux/store'
import { StatusEnum } from 'redux/types'
import { decodeToken } from 'utils/decodeToken'
import styles from './Login.module.scss'

const Login = () => {
  const [modalMessage, setModalMessage] = useState('Already a member')
  const styless: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: modalMessage === 'Already a member' ? 260 : 300,
    height: 170,
    overflow: 'hidden',
    backgroundColor: '#050624',
    color: 'white',
    borderRadius: '8px',
    p: 4,
  }
  const { id } = useParams()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [formattedPhoneNum, setFormattedPhoneNum] = useState('')
  const [errorType, setErrorType] = useState('')
  const [otpError, setOtpError] = useState(false)
  const [OTPScreen, setOTPScreen] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [disableVerifyBtn, setDisableVerifyBtn] = useState(true)
  const [modal, setModal] = useState(false)
  const [code, setCode] = useState('')
  const [verifyStatus, setVerifyStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (verifyStatus === StatusEnum.Success) {
      let localToken = localStorage.getItem(AppConstants.ACCESS_TOKEN)
      if (localToken) {
        const isPatient = decodeToken(localToken)
        if (isPatient && isPatient === 'PATIENT') {
          getProfile()
        } else {
          navigate(`/referral/${id}/download`)
        }
      }
    }
  }, [verifyStatus])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Referral|login',
      page_location: `/referral/${id}/login`,
    })
  }, [])

  const handleKeyDown = (e: any, nextFieldName: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const nextField = e.target.form.elements[nextFieldName]
      if (nextField) {
        nextField.focus()
      }
    }
  }

  const sendOtp = async () => {
    if (phoneNumber.length >= 4) {
      setDisableButton(true)
      const response: any = await axios
        .post(AppConstants.API_URL + '/auth/patient/login', {
          firstname: firstName,
          phone: '+' + phoneNumber.replace(/-/g, ''),
        })
        .then((res) => res)
        .catch((err) => err.response)
      if (
        response &&
        response?.data?.code === 'success' &&
        response?.data?.message === 'Verification code sent successfully'
      ) {
        setDisableButton(false)
        setOTPScreen(true)
      } else if (response && response?.data?.message === 'Phone number is not found') {
        setErrorType('Not_User')
        setDisableButton(false)
      } else if (response && response?.data.message === 'Unable to send verification code') {
        setErrorType('formatInvalid')
        setDisableButton(false)
      } else if (response && response.data && response.data.code >= 400 && response.data.code <= 599) {
        setErrorType('Error_Occurred')
        setDisableButton(false)
      } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
        setErrorType('Error_Occurred')
        setDisableButton(false)
      }
    } else {
      if (firstName.length === 0) {
        setErrorType('firstNameEmpty')
        setDisableButton(true)
      }
      if (phoneNumber.length < 4) {
        setErrorType('formatInvalid')
      }
    }
  }

  const onVerify = async () => {
    setDisableButton(true)

    if (code === '') {
      setOtpError(true)
      setErrorType('Empty')
      setDisableButton(false)
    } else {
      try {
        console.log('payload', {
          phone: '+' + phoneNumber.replace(/-/g, ''),
          code: code,
          signupThrough: 'web',
        })
        const response: any = await axios
          .post(AppConstants.API_URL + '/auth/patient/register/login/verify', {
            phone: '+' + phoneNumber.replace(/-/g, ''),
            code: code,
            signupThrough: 'web',
            firstname: firstName,
            referralCode: id,
          })
          .then((res) => res)
          .catch((err) => err.response)
        console.log(response, 'total response')

        if (response && response.data && response.data.code === 'success') {
          ReactGA.event('login', {
            method: 'web',
          })
          localStorage.setItem(AppConstants.ACCESS_TOKEN, response?.data?.data?.accessToken)
          let localToken = response.data?.data?.accessToken
          let tokenParts = localToken.split('.')

          let tokenPayload = tokenParts[1]
          let tokenPayloadStr = atob(tokenPayload)

          let decodedToken: any = JSON.parse(tokenPayloadStr)

          let tempCurrentRole: any = ''
          if (decodedToken) {
            if (decodedToken.roles && decodedToken.roles.length === 1) {
              tempCurrentRole = decodedToken.roles[0]
            }
          } else {
            tempCurrentRole = null
          }
          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
          // if (tempCurrentRole === 'PATIENT') {
          //   navigate('/add-photo')
          // } else {
          //   navigate('/signup')
          // }
          setVerifyStatus(StatusEnum.Success)
        } else if (response && response.data && response.data.code === 'invalid_value') {
          setOtpError(true)
          setErrorType('Invalid')
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.code === 'code_expired') {
          setOtpError(true)
          setErrorType('Code_Expired')
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.code === 'user_not_found') {
          setErrorType('Not_User')
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
          setErrorType('Error_Occurred')
          //   setVerifyStatus(StatusEnum.Failed)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      // if (response && response.data && response.data.data) {
      //   if (response.data.data.referralCode !== null && response.data.data.previousVisits === true) {
      //     if (!response.data.data.lastname || !response.data.data.email || !response.data.data.dob) {
      //       dispatch(SetSkipCongratsReferral(true))
      //       navigate(`/referral/${id}/signup`)
      //       return
      //     }
      //   }
      // }

      if (
        response &&
        response?.data.code === 'success' &&
        response?.data?.data?.referralCode !== null &&
        response?.data?.data?.previousVisits === true
      ) {
        setModalMessage('Already claimed your reward')
        setModal(true)
      } else if (
        response &&
        response?.data.code === 'success' &&
        response?.data?.data?.referralCode !== null &&
        response?.data?.data?.previousVisits === false
      ) {
        getAllStudios()
      } else {
        setModal(true)
      }
      // if (response && response?.data.code === 'success' && !response?.data?.data?.profilPic) {
      //   navigate(`/referral/${id}/add-photo`)
      // }
      // else if (
      //   response &&
      //   response?.data.code === 'success' &&
      //   response?.data?.data?.profilPic
      //   // response?.data?.data?.patient?.screeningStatus !== 'accepted'
      // ) {
      //   navigate(`/referral/${id}/physical-visit`)
      // }
      // else {
      //   navigate(`/referral/${id}/download`)
      // }
    } catch (error) {
      console.error(error)
    }
  }

  const getAllStudios = async () => {
    const response: any = await axios
      .get(AppConstants.API_URL + '/clinic', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })

    if (response?.data?.code === 'success') {
      dispatch(SetStudiosReferral(response?.data.data))
      if (response?.data.data) {
        dispatch(SetIsMoreThanOneStudioReferral(response?.data.data.length > 1 ? true : false))
        if (response?.data.data.length > 1) {
          navigate(`/referral/${id}/select-studio`)
        } else {
          navigate(`/referral/${id}/physical-visit`)
        }
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (phoneNumber.length >= 4 && firstName.length > 0) {
      setDisableButton(false)
    }
  }, [OTPScreen])

  useEffect(() => {
    dispatch(SetSelectedSlotReferralFlow(null))
    dispatch(SetSelectedStudioReferral(null))
    dispatch(SetStudiosReferral([]))
    dispatch(SetIsMoreThanOneStudioReferral(false))
  }, [])

  if (!OTPScreen) {
    return (
      <div className={styles.container}>
        <Header headerText="Let's Get Started" showBackButton={false}></Header>
        <div className={styles.loginDetail}>
          <div className={styles.formContainer}>
            <form>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {/* <TextField
                    autoFocus
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 12,
                    }}
                    sx={
                      errorType === 'fieldEmpty' || errorType === 'formatInvalid' || errorType === 'userNot'
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                            textTransform: 'capitalize',
                          }
                        : {
                            '& .MuiInputBase-input': {
                              textTransform: 'capitalize',
                            },
                          }
                    }
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    label="Phone Number"
                    name="phone"
                    error={errorType !== '' ? true : false}
                    onChange={(e) => {
                      const formattedValue = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 10)
                        .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_, first, second, third) => {
                          let result = first
                          if (second) result += '-' + second
                          if (third) result += '-' + third
                          return result
                        })
                      setErrorType('')
                      setPhoneNumber(formattedValue)
                      if (formattedValue.length === 12) {
                        setDisableButton(false)
                      } else {
                        setDisableButton(true)
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        if (!disableButton) {
                          sendOtp()
                        }
                      }
                    }}
                  /> */}
                  <TextField
                    autoFocus
                    sx={
                      firstNameError
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                          }
                        : {
                            '& .MuiInputBase-input': {
                              textTransform: 'capitalize',
                            },
                          }
                    }
                    style={{ marginTop: '5px' }}
                    placeholder="Enter first name"
                    label="First Name"
                    name="firstName"
                    value={firstName}
                    error={firstNameError}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                      if (e.target.value.length === 0 || phoneNumber.length < 4) {
                        setDisableButton(true)
                      } else {
                        setDisableButton(false)
                      }
                    }}
                    onKeyDown={(e) => handleKeyDown(e, 'phone')}
                  />
                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      autoFocus: false,
                    }}
                    country={'us'}
                    value={phoneNumber}
                    preferredCountries={['us', 'ca', 'gb', 'sa', 'ae']}
                    priority={['us', 'ca', 'gb', 'sa', 'ae']}
                    preserveOrder={['preferredCountries']}
                    containerClass={styles.phoneCtn}
                    dropdownClass={styles.dropdown}
                    specialLabel=""
                    inputClass={styles.input}
                    buttonClass={styles.countryBtn}
                    countryCodeEditable={false}
                    onChange={(value, e: any) => {
                      setPhoneNumber(value)
                      setErrorType('')
                      const formatVal = value.slice(e.dialCode.length)
                      setFormattedPhoneNum(formatVal)
                      if (formatVal.length < 4 || firstName.length === 0) {
                        setDisableButton(true)
                      } else {
                        setDisableButton(false)
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        if (!disableButton) {
                          sendOtp()
                        }
                      }
                    }}
                    inputStyle={
                      errorType === 'fieldEmpty' || errorType === 'formatInvalid' || errorType === 'userNot'
                        ? {
                            backgroundImage: "url('/images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                            textTransform: 'capitalize',
                            border: '2px solid #F24343',
                          }
                        : {}
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '130px', position: 'absolute', width: '92%' }}>
                {(errorType === 'fieldEmpty' ||
                  errorType === 'formatInvalid' ||
                  errorType === 'Not_User' ||
                  errorType === 'firstNameEmpty' ||
                  errorType === 'Error_Occurred') && (
                  <div className={styles.errorContainer}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                      {errorType === 'fieldEmpty' && `Enter Your Phone Number`}
                      {errorType === 'formatInvalid' && `Invalid Phone Number`}
                      {errorType === 'firstNameEmpty' && `Enter Your First Name`}
                      {errorType === 'Not_User' && `User not found`}
                      {errorType === 'Error_Occurred' && `Oops! something went wrong , try again later`}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: '12px',
                      }}
                    >
                      <img
                        onClick={() => {
                          setErrorType('')
                        }}
                        src={'/images/wrong.svg'}
                        style={{ cursor: 'pointer' }}
                      ></img>
                    </div>
                  </div>
                )}
              </Grid>
            </form>
            {/* <div style={{ marginTop: '1rem' }}>
              <Button
                // className={styles.submitBtn}
                id="send-otp"
                type="submit"
                sx={disableButton ? { opacity: '0.5' } : {}}
                children={'Send Passcode'}
                onClick={
                  disableButton
                    ? undefined
                    : () => {
                        sendOtp()
                      }
                }
              />
            </div> */}
            {/* <div className={styles.buttonContainer}>
              <div className={styles.scheduleText}>How would you like to schedule your appointment?</div>
              <Button
                className={styles.submitBtn}
                id="send-otp"
                type="submit"
                startIcon={<img src="/images/bookMyself.svg"></img>}
                sx={disableButton ? { opacity: '0.5' } : {}}
                children={'Continue'}
                onClick={
                  disableButton
                    ? undefined
                    : () => {
                        sendOtp()
                      }
                }
              ></Button>
            </div> */}
          </div>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={false}
          trackerIndex={10}
          isDisabled={disableButton}
          buttonId={'sign-in-button'}
          buttonType={'submit'}
          onButtonClick={() => {
            disableButton ? undefined : sendOtp()
          }}
        ></Footer>
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        <Header
          headerText=""
          showBackButton={modal === true ? false : true}
          onBack={() => {
            setCode('')
            setOTPScreen(false)
            setErrorType('')
            setOtpError(false)
            setPhoneNumber(phoneNumber)
            setDisableVerifyBtn(true)
          }}
          hideIcon={false}
        />
        <div className={styles.loginDetail} style={{ top: '50%' }}>
          <div className={styles.formContainer}>
            <div>
              <p style={{ color: 'white', marginBottom: 20, lineHeight: 1.6, fontSize: '18px' }}>
                Please enter the 6 digit code sent to <br></br>
                <b>{formattedPhoneNum}</b> through SMS.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  if (!disableVerifyBtn) {
                    onVerify()
                  }
                }}
              >
                <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        maxLength: 6,
                      }}
                      sx={
                        otpError
                          ? {
                              backgroundImage: "url('images/error.svg')",
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'right 15px center',
                              textTransform: 'capitalize',
                            }
                          : {}
                      }
                      value={code}
                      placeholder="Enter Code"
                      label="Verification Code"
                      name="code"
                      // type="number"
                      error={otpError}
                      onChange={(e) => {
                        setCode(e.target.value)
                        setOtpError(false)
                        if (e.target.value.length >= 6) {
                          setDisableVerifyBtn(false)
                        } else {
                          setDisableVerifyBtn(true)
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
              <Grid item xs={12} sx={{ marginTop: '90px', position: 'absolute', width: '92%' }}>
                {otpError && (
                  <div className={styles.errorContainer}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                      {errorType === 'Empty' && `Please enter passcode`}
                      {errorType === 'Invalid' && `Invalid Code`}
                      {errorType === 'Code_Expired' && `Oops! code expired, Click Resend`}
                      {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                      {errorType === 'Not_User' && `User not found`}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: '12px',
                      }}
                    >
                      <img
                        onClick={() => {
                          setOtpError(false)
                        }}
                        src={'/images/wrong.svg'}
                        style={{ cursor: 'pointer' }}
                      ></img>
                    </div>
                  </div>
                )}
              </Grid>
            </div>
          </div>
          {/* <div className={styles.btnWrapper}>
            <Button
              id="sign-in-button"
              sx={disableVerifyBtn ? { opacity: '0.5' } : {}}
              type="submit"
              children={'Verify'}
              onClick={
                disableVerifyBtn
                  ? undefined
                  : () => {
                      onVerify()
                    }
              }
            />
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.8rem' }}>
            <div
              className={styles.signUpText}
              onClick={() => {
                sendOtp()
              }}
            >
              Resend OTP{'>'}
            </div>
            <div
              className={styles.signUpText}
              onClick={() => {
                setOTPScreen(false)
                setCode('')
                setDisableVerifyBtn(true)
                if (otpError || errorType !== '') {
                  setErrorType('')
                  setOtpError(false)
                }
              }}
            >
              Wrong Number?
            </div>
          </div>

          {modal && (
            <Dialog onClose={() => {}} open={modal} PaperProps={{ style: styless }} className={styles.modalPopUp}>
              <DialogTitle
                style={{
                  fontSize: '14.5px',
                  marginTop: '0rem',
                  marginLeft: modalMessage === 'Already a member' ? '0.5rem' : '0.2rem',
                }}
              >
                {modalMessage === 'Already a member'
                  ? 'You’ve previously signed up. Please download the KIRO mobile app to continue.'
                  : 'You’ve already claimed your reward. Please download KIRO app to book an adjustment.'}
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  navigate(`/referral/${id}/download`)
                }}
              >
                Ok
              </Button>
            </Dialog>
          )}
        </div>
        <Footer
          buttonText={'Verify'}
          isLoading={false}
          trackerIndex={10}
          isDisabled={disableVerifyBtn}
          buttonId={'sign-in-button'}
          buttonType={'submit'}
          onButtonClick={() => {
            disableVerifyBtn ? undefined : onVerify()
          }}
        ></Footer>
      </div>
    )
  }
}

export default Login
